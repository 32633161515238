import React, { Fragment, useEffect, useMemo, useState } from "react";
import { renderRoutes } from "react-router-config";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import memoize from "memoize-one";
import _noop from "lodash/noop";

import PurchaseOrderTab from "./PurchaseOrderTab";
import NavigationBar from "../../../layout/NavigationBar/NavigationBar";
import PurchaseOrder from "../PurchaseOrder";

import {
  initDataComponent,
  performRetrieveListRequest,
  setReload,
} from "../../../../actions/dataComponentActions";
import { expeditingPOList } from "../../../../actions/expeditingActions";
import {
  closeModalDialog,
  openModalDialog,
} from "../../../../actions/layoutActions";
import { getDataComponentFlattenedRequestState } from "../../../../reducers/dataComponentReducer";

import propTypes from "../../../../constants/propTypes";
import * as RequestTypes from "../../../../constants/RequestTypes";
import Shipment from "../../../../models/Shipment";
import { roleCan } from "../../../../utils/roleUtils";
import {
  shipmentsDataComponentId as ShipmentsTabDCId,
  dataComponentId as SpecShipmentsDCId,
} from "../Shipments/ShipmentsContainer";
import { dataComponentId as CreateShipmentsDCId } from "../Shipments/CreateShipment/CreateShipmentContainer";
import { dataComponentId as EditShipmentsDCId } from "../Shipments/EditShipment/EditShipmentContainer";
import { dataComponentId as DeleteShipmentsDCId } from "../Shipments/DeleteShipment/DeleteShipmentContainer";
import { dataComponentId as POTabDCId } from "../PurchaseOrder/PurchaseOrderContainer";
import { getLeftChildren } from "../../Quoting/QuotingPage";
import { getFilters, poFields } from "./filterOptions";
import { ShipmentsTab } from "./ShipmentsTab";
import { ExpeditingFiltersContext } from "./ExpeditingFiltersContext";

const purchaseOrdersDataComponentId = "Expediting-purchase-orders";
const shipmentsDataComponentId = "Expediting-shipments";
const dataComponentId = "PurchaseOrdersGrid";

const requestTypes = Object.values(RequestTypes).filter(
  type => type !== RequestTypes.LIST
);

export function getNavigationTabs(project, followUpCounters, role, events) {
  const shipmentListeners = [
    { dataComponentId: ShipmentsTabDCId, requestTypes },
    { dataComponentId: SpecShipmentsDCId, requestTypes },
    { dataComponentId: CreateShipmentsDCId, requestTypes },
    { dataComponentId: EditShipmentsDCId, requestTypes },
    { dataComponentId: DeleteShipmentsDCId, requestTypes },
  ];
  return [
    {
      text: "Purchase Orders",
      label: (
        <PurchaseOrderTab
          project={project}
          counter={followUpCounters.purchaseOrders}
          onUpdate={events.onUpdatePOsCount}
          listeners={{
            pos: [
              { dataComponentId: POTabDCId, requestTypes },
              ...shipmentListeners,
            ],
          }}
        />
      ),
      path: "/purchase-orders",
      permission: "project-detail-expediting-purchase-orders",
    },
    {
      text: "Shipments",
      label: (
        <ShipmentsTab
          project={project}
          counter={followUpCounters.purchaseOrders}
          onUpdate={events.onUpdatePOsCount}
          listeners={{
            pos: [
              { dataComponentId: expeditingPOList, requestTypes: RequestTypes },
              { dataComponentId: POTabDCId, requestTypes },
              ...shipmentListeners,
            ],
          }}
        />
      ),
      path: "/shipments",
      permission: "project-detail-expediting-purchase-orders",
    },
  ].filter(tab => roleCan(role, tab.permission));
}

export const getShipmentsQuery = memoize(projectId => ({
  rootFilters: {
    $where: {
      needsFollowUp: true,
      "spec.projectId": projectId,
    },
  },
  count: true,
}));

export const getPOsQuery = memoize(projectId => ({
  rootFilters: {
    $where: {
      projectId,
      status: "Issued",
      needsFollowUp: true,
    },
  },
  count: true,
  params: {
    omitDefaultModifier: true,
    modifiers: ["withBasicFields", "withNeedsFollowUp"],
  },
}));

export const initExpediting = (
  initDataComponent,
  performRetrieveListRequest,
  projectId
) => () => {
  initDataComponent(
    purchaseOrdersDataComponentId,
    PurchaseOrder,
    [],
    "purchase-orders"
  );
  initDataComponent(shipmentsDataComponentId, Shipment, [], "shipments");

  performRetrieveListRequest(
    purchaseOrdersDataComponentId,
    getPOsQuery(projectId)
  );
  performRetrieveListRequest(
    shipmentsDataComponentId,
    getShipmentsQuery(projectId)
  );
};

export const Expediting = ({
  project,
  followUpCounters,
  route,
  role,
  initDataComponent,
  performRetrieveListRequest,
  setReload,
  closeModalDialog,
  openModalDialog,
  projectId,
  location,
}) => {
  const isPOTab = location.pathname.match(/expediting\/purchase-orders/);
  const isShipmentsTab = location.pathname.match(/expediting\/shipments/);
  const [filterOptionsPO, setFilterOptionsPO] = useState({});
  const [filterOptionsBG, setFilterOptionsBG] = useState({});
  useEffect(
    initExpediting(initDataComponent, performRetrieveListRequest, project.id),
    [initDataComponent, performRetrieveListRequest, project.id]
  );

  const handleUpdateShipmentsCount = () => {
    performRetrieveListRequest(
      shipmentsDataComponentId,
      getShipmentsQuery(project.id)
    );
    setReload(SpecShipmentsDCId, true);
  };

  const handleUpdatePOsCount = () => {
    performRetrieveListRequest(
      purchaseOrdersDataComponentId,
      getPOsQuery(project.id)
    );
  };

  const handleOpenFilter = () => {
    const { count, ...defaultFilters } = getPOsQuery(project.id);
    const options = {
      purchaseOrder: {
        title: "Purchase Orders",
        fields: poFields(),
        dataComponentId: dataComponentId,
        filterMethod: (_, filters) => {
          performRetrieveListRequest(dataComponentId, {
            ...defaultFilters,
            rootFilters: {
              $where: {
                projectId,
                status: "Issued",
                $and: {
                  ...filters,
                },
              },
            },
          });
        },
      },
      shipments: {
        title: "Shipments",
        fields: poFields(true),
        dataComponentId: expeditingPOList,
        filterMethod: _noop,
      },
    };

    let option = options.shipments;
    let setFilterOptions = setFilterOptionsBG;
    let initialValues = filterOptionsBG;
    if (isPOTab) {
      option = options.purchaseOrder;
      setFilterOptions = setFilterOptionsPO;
      initialValues = filterOptionsPO;
    }

    openModalDialog(
      "Filter",
      "QuotingFilter",
      {
        projectId,
        dataComponentId: option.dataComponentId,
        onFilter: data => {
          setFilterOptions(data);
          closeModalDialog();
        },
        defaultFilters: {
          projectId,
          status: "Issued",
        },
        initialValues,
        ...option,
      },
      false,
      false,
      {
        scrollDisabled: true,
        subtitle: option.title,
      }
    );
  };

  const navigationTabs = getNavigationTabs(project, followUpCounters, role, {
    onUpdateShipmentsCount: handleUpdateShipmentsCount,
    onUpdatePOsCount: handleUpdatePOsCount,
  });
  const showLeftChildren = isPOTab || isShipmentsTab;
  const filterOptions = isPOTab ? filterOptionsPO : filterOptionsBG;
  const filters = useMemo(() => getFilters(filterOptions), [filterOptions]);
  return (
    <Fragment>
      <NavigationBar
        navigationTabs={navigationTabs}
        leftChildren={
          showLeftChildren &&
          getLeftChildren(false, "", handleOpenFilter, filterOptions)
        }
      />
      <ExpeditingFiltersContext.Provider value={{ filters }}>
        {renderRoutes(route.routers)}
      </ExpeditingFiltersContext.Provider>
    </Fragment>
  );
};

Expediting.propTypes = {
  project: PropTypes.object,
  initDataComponent: PropTypes.func,
  setReload: PropTypes.func,
  performRetrieveListRequest: PropTypes.func,
  followUpCounters: PropTypes.object,
  route: propTypes.route,
  role: propTypes.userRole,
  closeModalDialog: PropTypes.func,
  openModalDialog: PropTypes.func,
  projectId: PropTypes.string,
  location: PropTypes.shape({ pathname: PropTypes.string }),
};

export const mapDispatchToProps = {
  initDataComponent,
  performRetrieveListRequest,
  setReload,
  closeModalDialog,
  openModalDialog,
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { projectId },
    },
  }
) => {
  const purchaseOrdersDataComponent = getDataComponentFlattenedRequestState(
    purchaseOrdersDataComponentId,
    state
  );

  const shipmentsDataComponent = getDataComponentFlattenedRequestState(
    shipmentsDataComponentId,
    state
  );

  return {
    projectId,
    role: state.auth.role,
    followUpCounters: {
      shipments: shipmentsDataComponent.totalRows,
      purchaseOrders: purchaseOrdersDataComponent.totalRows,
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Expediting);
